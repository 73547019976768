/* Notification.css */
.notification {
  display: flex;
  align-items: center;
  background-color: #f0f4ff;
  padding: 5px 20px 5px 2px;
  border-radius: 5px;
  font-size: 14px;
  color: #000;
  position: relative;
}

.customNotificationImage {
  padding: 6px;
}

.close-icon {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 10px;
}

.close-icon:hover {
  color: #64c3ff;
}

.message {
  width: fit-content;
  margin: auto 20px auto 4px;
}
