.sidebar {
  position: fixed;
  height: 100%;
  z-index: 900;
  top: 0;
  padding-top: 40px;
}

.content {
  margin-left: 3.5rem;
}

.blurred {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.dashboard-card {
  height: 276px;
  width: 447px !important;
}
.upload-file-name {
  margin-top: 15px;
  font-size: 13px;
}
.upload-file-close {
  float: right;
  cursor: pointer
}
.success-upload-response {
  color: #68d560;
}
.failure-upload-response {
  color: red;
  display: flex;
  align-items: center;
  width: 100%;
}
.upload-file-name.mn{
  margin-top:23px
}
.error-upload-icon{
  margin-top:5px;
  height:12px;
width: 14px;
}
.success-upload-response svg {
  height: 20px;
  padding-top: 5px;
}
.error-upload-message{
  margin-left:5px;
  margin-top:8px
}
.error-message {
  color: #EE3426;
  font-size: 15px;
  margin-top: 10px;
}
.retry-upload{
  margin-top:8px;
  margin-left: 250px;
}

.OpenFullscreenIcon{
  float: right;
  margin-top: 25px;
  height: 20px !important;
  width: 20px !important;
}
.dashboard-card.ami {
  background: linear-gradient(
      106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%
    ),
    url("../../../assets/image15.png") 100% 100%;
}

.dashboard-card.asset-monitor {
  background: linear-gradient(
      106.02deg,
      rgba(0, 0, 0, 0.94) 51.62%,
      rgba(0, 0, 0, 0.5076) 91.16%
    ),
    url("../../../assets/image17.png") 100% 100%;
}

.dashboard-card.admin {
  background: linear-gradient(
      101.69deg,
      rgba(0, 0, 0, 0.94) 42.22%,
      rgba(0, 0, 0, 0.3948) 62.84%
    ),
    url("../../../assets/image18.png") 100% 100%;
}

.dashboard-card.blaze {
  background: linear-gradient(
      101.95deg,
      rgba(0, 0, 0, 0.94) 36.25%,
      rgba(0, 0, 0, 0.5076) 76.58%
    ),
    url("../../../assets/image20.png") 100% 100%;
}

.dashboard-card.ami-project {
  background: linear-gradient(
      103.52deg,
      rgba(0, 0, 0, 0.94) 44.55%,
      rgba(0, 0, 0, 0.5076) 78.4%
    ),
    url("../../../assets/image22.png") 100% 100%;
}

.dashboard-card.data-publisher {
  background: linear-gradient(
      103.52deg,
      rgba(0, 0, 0, 0.94) 44.55%,
      rgba(0, 0, 0, 0.5076) 78.4%
    ),
    url("../../../assets/image14.png") 90% 90%;
}