/* To make the footer stick to bottom when content is less */

#MainLayoutFlexContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#bottom-flex-container {
  flex-grow: 1;
  display: flex;
}

.sidebar-layout {
  flex-grow: 1;
}

.sidebar-layout>.content {
  display: flex;
}

#sidebar-layout-content-flex-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ui.page-header.has-menu {
  border: none !important;
}

.sidebar-layout>.sidebar {
  border-right: none !important;
}

.ui.page-header .page-header-menu.ui.menu .main-logo {
  border: none;
}

.outlet-content {
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
}

.device-table .MuiButtonBase-root:hover{
  color: #e0e0e0 !important;
}

.device-table .MuiButtonBase-root:focus{
  color: #e0e0e0 !important;
}

/* AMI Meter Data Collection */

.ami-meter-data-collection-card {
  height: 276px;
  width: 476px !important;
  background: linear-gradient(106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%),
    url("./assets/image15.png") 100% 100%;
}

#issues-tab {
  width: 100%;
}

#issues-progress {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1em 1em 0;
  background-color: #313030;
}

.issues-progress-bar {
  width: 13vw;
  height: 20px;
}

#issues-progress-bar1 {
  accent-color: #ff644c;
}

#issues-progress-bar2 {
  accent-color: #ff9847;
}

#issues-progress-bar3 {
  accent-color: #64c3ff;
}

.issue-type-container {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
}

.issue-type-container span {
  padding: 0.5em 1em;
  padding-left: 0.5em;
}

.issue-type-container span:nth-child(odd) {
  width: 15em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.issue-type-container span:nth-child(even) {
  color: #64c3ff;
}

.amiDetailsButton {
  background-color: transparent;
  border: 1px solid #64c3ff;
  color: #64c3ff;
  padding: 8px;
  border-radius: 6px;
  font-weight: 400;
}

.amiDetailsButton:hover {
  cursor: pointer;
}

.success-provision {
  background-color: #68d560;
  display: inline-flex;
  color: #303030;
  font-size: 11px;
  align-items: center;
  text-transform: uppercase;
}
.success-provision-outlined {
  display: inline-flex;
  color: #68d560;
  font-size: 11px;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #68d560;

}
.error-provision {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: #ff6f67;
  font-size: 11px;
  border: 1px solid #ff6f67;
}
.idle-provision-outlined {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: #d0d0d0;
  font-size: 11px;
  border: 1px solid #d0d0d0;
}

.firmware-details-heading {
  font-weight: bold;
}

.filter-title {
  color: var(--typography-dark-base-default-f-0-f-0-f-0, #F0F0F0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Honeywell Sans Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.devices{
  border: none !important;
  border-radius: 0 !important;
}

.cardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid red;
}

@media only screen and (max-width: 1800px) {
  .issues-progress-bar {
    width: 16vw;
  }

  .issue-type-container {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1650px) {
  .issue-type-container {
    font-size: 15px;
  }

  #issues-progress {
    padding: 0px 1em 20px 0px;
  }

  .issues-progress-bar {
    width: 100%;
    height: 20px;
  }

  .issue-type-container span {
    padding: 0.5em 1em;
    padding-left: 0.5em;
  }
}

@media only screen and (max-width: 1368px) {
  .issue-type-container {
    font-size: 13px;
  }

  #issues-progress {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 1em 20px 0px;
  }

  .issues-progress-bar {
    width: 100%;
    height: 20px;
  }

  .issue-type-container span {
    padding: 0.5em 0.5em;
    padding-left: 0.5em;
  }
}

@media only screen and (max-width: 1300px) {
  .issue-type-container {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1150px) {
  .issue-type-container {
    font-size: 9px;
  }
}

/* Asset Monitor */

.asset-monitor-card {
  height: 276px;
  width: 476px !important;
  background: linear-gradient(106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%),
    url("./assets/image17.png") 100% 100%;
}

.admin-card {
  height: 276px;
  width: 476px !important;
  background: linear-gradient(106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%),
    url("./assets/image18.png") 100% 100%;
}

.admin-card-titles {
  letter-spacing: 3.5px !important;
  font-weight: 600 !important;
}
.blaze-card {
  height: 276px;
  width: 476px !important;
  background: linear-gradient(106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%),
    url("./assets/image20.png") 100% 100%;
}

.ami-project-status-card {
  height: 276px;
  width: 476px !important;
  background: linear-gradient(106.1deg,
      rgba(0, 0, 0, 0.94) 55.11%,
      rgba(0, 0, 0, 0.5076) 82.71%),
    url("./assets/image22.png") 100% 100%;
}

.search-wrap {
  margin-right: 1em;
}

/* AMImeterCollection */

.WizardTitle{
  display: 'flex';
  justify-content: space-between;
  margin-top: 35px;
  margin-left: 25px;
  margin-right: 40px;
  font-weight:600;
  font-size: larger;
}

.top-issues-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px 0 15px;
  background-color: #313030;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 63px;
}

.topUtilityBar {
  margin: 1.2em 1.2em 0.5em 0.2em;
}

.componentDashboard {
  margin: 1.2em;
}

#backButton {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

#backButton:hover {
  cursor: pointer;
  top: -3px;
}

#componentTitle {
  padding: 0.4em;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

#top-right-ami-utilities {
  float: right;
  margin-right: 10px;
}

#top-right-filters {
  background-color: #505050;
  padding: 5px;
  padding-left: 2px;
  border-radius: 3px;
}

#top-right-filters button {
  background-color: transparent;
  border: none;
  color: #d0d0d0;
}

#top-right-filters button:hover {
  cursor: pointer;
  color: #64c3ff;
}

#top-right-filter-active-btn {
  background-color: #303030 !important;
  padding: 4px;
}

.circleGaugeChart .highcharts-container {
  height: 200px !important;
}

#top-right-ami-btn {
  background-color: #64c3ff;
  height: 30px;
  border-radius: 4px;
  margin-left: 10px;
  border: none;
}

#top-right-ami-btn:hover {
  cursor: pointer;
}

/* Ami Chart begin */

.centerChart {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.circleChartDataComp {
  /* width: fit-content; */
  flex-grow: 1;
  /* border: 1px solid red; */
}

.ami-chart-legends {
  font-size: 14px;
  font-weight: 500;
  /* flex-grow: 1; */
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid green; */
}

@media only screen and (max-width: 1368px) {
  .ami-chart-legends {
    font-size: 12px;
    /* border: 1px solid green; */
  }
}

/* .apexcharts-radialbar-track.apexcharts-track path {
  stroke: rgba(0, 0, 0, 0) !important;
} */

.indivLegendRow td {
  vertical-align: top;
  margin: 10px;
  height: fit-content;
}

.indivLegendRow td:nth-child(2) {
  width: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.indivLegendRow td:nth-child(3) {
  width: auto;
}

.indivLegendRow td:first-child {
  width: 4px;
}

#data-comp-container .highcharts-container {
  width: 100%;
}

#data-comp-container .ui.forge.chart-wrap.basic-chart {
  width: 100% !important;
}

/* Ami Chart ends */

#popupDivAmi {
  font-size: 14px;
  color: black;
}

#popupDivAmi span {
  font-weight: 700;
}

#totalDevicesTitle {
  margin-left: 1em;
  padding-left: 1em;
  border-left: 1px solid white;
}

#totalDevicesTitle>span {
  font-weight: 400;
}

.dashboard-tiles-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.forge.chart-wrap.timeseries-chart .highcharts-legend .highcharts-legend-item span,
.ui.forge.chart-wrap.timeseries-chart .highcharts-legend .highcharts-legend-title span,
.ui.forge.chart-wrap.timeseries-chart .highcharts-legend .legend-custom-label,
.ui.forge.chart-wrap.basic-chart .highcharts-legend .highcharts-legend-item span,
.ui.forge.chart-wrap.basic-chart .highcharts-legend .highcharts-legend-title span,
.ui.forge.chart-wrap.basic-chart .highcharts-legend .legend-custom-label {
  font-size: 14px;
}

#critical-btn {
  width: 91px;
  height: 24px;
  border-width: 0px;
  border-radius: 4px;
  background-color: #ff644c;
}

#high-btn,
#high-btn:active,
#high-btn:focus {
  border-width: 1px;
  border-color: white;
  border-radius: 4px;
  background-color: transparent;
  color: #ff644c;
  width: 91px;
  height: 24px;
}

#medium-btn {
  border-width: 0px;
  border-radius: 4px;
  width: 91px;
  height: 24px;
  background-color: #ff9847;
}

/* Maps */

.map__container {
  height: 100% !important;
  width: 100% !important;
}

.map-tile-layer {
  filter: grayscale(100%);
}

.heatMapCard {
  height: 350px;
}

.MapCard {
  height: 350px;
}

/*
 Ami Issues Page */

.ami-issues-types-flex-container1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1em;
}

.ami-issues-types-flex-container2 {
  display: flex;
  width: 85%;
  justify-content: space-between;
  color: #d0d0d0
}

.ami-issues-types-flex-container3 {
  /* position: absolute; */
  width: 147px;
  height: 20px;
  left: calc(50% - 147px / 2 - 62.5px);
  top: calc(50% - 20px / 2 - 277px);

  font-family: "Honeywell Sans Web";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #64c3ff;
  margin-top: 25px;
  display: flex;
}

.ami-issues-types-flex-container4 {
  /* position: absolute; */
  width: 230px;
  height: 36px;
  left: calc(50% - 230px / 2 + 715px);
  top: 214px;

  font-family: "Honeywell Sans Web";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  color: #f0f0f0;
}

.ami-issues-types-flex-container5 {
  width: 129px;
  height: 24px;
  left: calc(50% - 129px/2 - 95.5px);
  bottom: 866px;

  /* Body/ Body 1

Use for body text and placeholder text
*/
  font-family: 'Honeywell Sans Web';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #D0D0D0;
}

.ami-issues-types-flex-container6 {
  display: flex;
  width: 85%;
  justify-content: space-between;
  color: #D0D0D0
}

.ami-issues-types-flex-container7 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #F0F0F0;
  border: 2px solid red
}

.ami-issues-types-flex-container8 {
  display: flex;
  width: 85%;
  justify-content: space-between;
  color: #D0D0D0;
  margin-top: 25px;
}

.ami-issues-types-flex-container9 {
  display: flex;
  width: 85%;
  justify-content: space-between;
  color: #F0F0F0
}

.ami-issues-types-flex-container10 {
  display: flex;
  width: 94%;
  justify-content: space-between;
  color: #F0F0F0
}

.ami-issues-types-flex-container11 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #F0F0F0
}

.ami-issues-types-flex-container12 {
  display: flex;
  width: 90%;
  justify-content: space-between;
  color: #F0F0F0;
  margin-top: 2em
}

.ami-issues-types-flex-container13 {
  width: 312px;
  height: 24px;
  left: 0px;
  top: 548px;
  margin-top: 1em;

  /* Body/ Body 1

      Use for body text and placeholder text
      */
  font-family: 'Honeywell Sans Web';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #F0F0F0;

}

.ami-issues-types-flex-container14 {
  margin-top: 60px;
  margin-left: 25%;
  width: 150px;
  background-color: #AEDCF5;
  padding: 5px
}

#high-btn-issues {
  border-width: 1px;
  border-color: rgb(160, 150, 150);
  border-radius: 4px;
  background-color: transparent;
  color: #f7f4f3;
  width: 91px;
  height: 24px;
}

#topLeftbuttonsissuePage {
  float: right;
}

.RedSquare {
  height: 30px;
  border-radius: 5px;
  line-height: 15px;
  background-color: #505050;
  color: white;
}

.YellowSquare {
  height: 30px;
  border-radius: 5px;
  line-height: 15px;
  background-color: #303030;
  color: white;
}

.BlueSquare {
  height: 30px;
  border-radius: 5px;
  line-height: 15px;
  background-color: #505050;
  color: white;
}

.issueCardTime {
  font-size: 12px;
  color: #d0d0d0;
  float: right;
}

.issueCardBtn1 {
  margin-top: 60px;
  width: 100%;
  background-color: #aedcf5;
  padding: 5px;
  font-weight: 700;
  color: #005eac;
  border-radius: 4px;
}

.issueCardBtn2 {
  margin-top: 10px;
  width: 100%;
  background-color: #64bbff;
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
}

@keyframes load {
  0% {
    background-color: #181818;
    opacity: 1;
  }

  50% {
    background-color: #303030;
    opacity: 0.6;
  }

  100% {
    background-color: #181818;
    opacity: 1;
  }
}

.ui.forge-datatable-wrap .p-datatable tbody.p-datatable-tbody .datatable-loading-body .loading-indicator {
  height: 32px;
  animation: load 1s linear 0s normal infinite none running;
}

/* Error Page */
.errorPageButton {
  display: block;
  text-align: center;
}

.errorPageButton button {
  margin: 1.2em;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #c5c5c7;
  color: #0c0c0c;
  border: 1px;

}
.errorPageButton button:hover {
  cursor: pointer;
}

.errorImg {
  display: flex;
  justify-content: center;
  align-items: center
}

.ChannelDataError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red
}

/* Asset summary */

.assetSummaryRow {
  margin-bottom: 20px;
}

.assetSummaryListHead {
  font-size: 1rem;
  font-weight: 600;
}

.assetSummaryListContent {
  font-size: 0.875rem;
  text-align: justify;
}

.ipandporttextbox input[type='text'] {
  border: none;
}

.assetDetailsTabsActive {
  font-size: '12px';
  color: '#F0F0F0';
}

.assetDetailsTabsInActive {
  font-size: '12px';
  color: '#A0A0A0';
}

.deviceDetailsProgress {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
}
.ChannelDataErrorPopup{
  padding: 8px;
}

/* Comment */
.modal{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}
.modalCotent{
  position: absolute;
  width: 40vw;
  height: 40vh;
  top: 30vh;
  left: 30vw;
}
/* Topissue Wizard*/
.Mui-selected {
  color: aliceblue !important;
}
.MuiToggleButtonGroup-root button {
  height: 34px !important;
}
.MuiToggleButtonGroup-root  > .MuiButtonBase-root{
  color: #D0D0D0 !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  color: #D0D0D0 !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton {
  color: #D0D0D0 !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  color: #f0f0f0 !important;
}

.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root > li {
  color: #D0D0D0 !important;
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeSmall > button {
  color: #D0D0D0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper {
  background-color: #404040 !important;
}

.MuiPickersCalendarHeader-label {
  color: #D0D0D0;
}

.MuiPickersYear-yearButton {
  color: #D0D0D0 !important;
}

div[aria-label="channel-dropdown"] > .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px;
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin[aria-selected="true"] {
  background-color: #64C3FF !important;
  color: #303030 !important;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin[aria-selected="false"] {
  color: #f0f0f0;
  font-size: 16px;
}

ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root > li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected[aria-selected="true"] {
  background-color: #1792E51A !important;
  color: #64C3FF !important;
  font-weight: 500;
}

.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar > button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium:nth-child(2){
  color: #64C3FF !important;
}



span.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel[role="columnheader"] {
  color: #D0D0D0;
  font-size: 16px;
}

.MuiPickersYear-root > button.MuiPickersYear-yearButton.Mui-selected {
  background-color: #1792E51A !important;
  color: #64C3FF !important;
  font-weight: 500;
  border-radius: 0;
}

.MuiPickersCalendarHeader-label {
  font-size: 20px;
  color: #f0f0f0;
}

.FilterButtonAmi{
  border-radius: 6px;
  background-color: #707070;
  height: 32px;
  width: 120px;
  font-weight: bold;
  color: white
}
.filterIcon{
  float: left
}
#searchandfilter{
  display: flex
}
.IconButton{
  border-radius: 5px;
  background-color: #707070;
  width: 120px;
  height: 30px;
  margin-bottom: 10px
}
.MuiSvgIcon-root{
  float: left;
}
.searchBarIssues{
  position: relative
}
.searchBarIssues input[type="search"]{
  background-color: #505050 !important;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border-color: #505050 !important
}
.searchBarIssues input[type="search"]::placeholder{
  color: rgb(239, 234, 234);
  opacity: 1;
}
.searchInput{
  width: 150px;
  height: 28px;
  margin-left: 4px
}
.searchIcon{
  position: absolute;
  top: 2px;
  left: 127px
}
.PaginationAmi{
  display: flex;
  justify-content: space-between;
  margin-top: 10px
}
.PaginationAmiRows{
  margin-left: 15px;
  font-size: smaller;
  color: #D0D0D0;
}
.Gotopages{
  margin-right: 15px;
  font-size: smaller;
  color: #D0D0D0;
  display: flex
}
.PaginationInput{
  width: 25px;
  height: 20px;
  margin-left: 5px;
  background-color: #505050;
  color:rgb(239, 234, 234);
  border-color: #505050;
}
.PaginationInput::placeholder{
  color: #D0D0D0;
}
.total-items{
  margin-left: 15px;
}

.CircularprogressAmi{
  display: flex;
  justify-content: center;
  align-items: center
}

.AmiTableGoto{ width: 40px;
  margin-right: 15px;
  margin-left: 10px;
  margin-bottom: 10px
}
.deviceComponentAmiHeading{
  display: flex;
  float: right;
  margin-right: 1vw;
  border-radius: 8px;
  margin-bottom: 0.5vw;
  padding: 5px !important;
  font-size: 0.7px !important
}
.TotalDeviceComponent{
  font-size: 14px;
  color: d0d0d0
}
.DevicelistDeviceComponent{
  color: #f0f0f0;
  font-size: 24px;
  font-weight: 900;
  border-right: 2px solid #e0e0e0;
  padding-right: 16px;
  margin-right: 10px
}
.AmiHeading{
  width: 100%;
  display: flex
}
.DeviceDetailHeading{
  width: 100%;
  display: flex;
  margin-left: inherit;
}
.ArrowAmi{
  display: flex;
  justify-content: space-between
}
.EnhancedtoolbarDivBox{
  display: flex;
  padding: 24px
}
.DeviceListTextfield{
  display: flex;
  align-items: center
}
.ArrowDeviceList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
}


/* Active Issues */

.active-issues {
  padding: 0 !important;
}

.css-1y4v7ra-MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.activeIssuesdiv {
  width: fit-content;
  color: #F0F0F0;
  padding: 1em;
}

.goTo{
  margin-top: 10px;
}
.gotoAndTextfield{
  display: flex;
  margin-right: 40px !important;
}

/* configuration */

.parameters-table-heading {
  width: 100%;
  color: #F0F0F0;
  padding: 1.5em;
  background-color: #303030;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid black;
}

.custom-textfield {
  background-color: #404040;
  border-radius: 4px;
  padding: 0;
}

.custom-textfield::placeholder {
  color: white !important;
}

.elementOnHover {
  position: relative;
  top: 0;
  transition: top ease 0.35s;
}

.elementOnHover:hover {
  cursor: pointer;
  top: -3px;
}
/* AuthError */

.authError{
  height: 40%;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.authErrorThirtyDaysTend {
  height: fit-content;
  margin: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.errorComp{
  display: flex;
  flex-direction: column;
  width: 32%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
}

.errorCompKpi {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
}

.errorCompInsideModal{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
}

.errorCompInsideGroupModal{
  display: flex;
  flex-direction: column;
  height: 70%;
  width: 70%;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
  margin-left: 4em;
  margin-top: 1em;
}

.errorInsideGroupModal{
  display: flex;
  flex-direction: column;
  height: 70%;
  width: 70%;
  justify-content: center;
  align-items: center;
  background-color: #404040;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
  margin-top: 1em;
}

.errorComp1{
  width: 35%;
  height: 50%;
  margin-bottom: 2em
}

.errorComp1Group{
  width: 35%;
  height: 50%;
  margin-bottom: 0.7em;
  margin-top: 1.5em
}

.errorCompButton{
  margin-top: 16px;
  background-color: #64C3FF;
  color: black;
  font-weight: 400;
  letter-spacing: 10;
  padding: 8px 26px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 2.5em;
  box-shadow: none;
  border: none;
  margin-bottom: 1em;
}
.errorCompContent{
  letter-spacing: 1.5px;
  color: white;
  margin-bottom: 1.2em;
}

.errorCompGroupContent{
  letter-spacing: 1px;
  color: white;
  font-size: xx-small;
  margin-bottom: 1.2em;
}

.errorCustomGroupContent{
  letter-spacing: 1px;
  color: white;
  font-size: 'medium';
  margin-bottom: 1.2em;
}

/* Generic Dropdown */
div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  background-color: #303030 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  transition: none !important;
}

.generic-dropdown {
  box-shadow: none !important;
  background-color: #00000000 !important;
  border: 1px solid #404040 !important;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0px !important;
}
/* end */


/* Parameter Table */

.parameter-table::-webkit-scrollbar {
  width: 10px;
}

.parameter-table::-webkit-scrollbar-track {
  background-color: #272727;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.parameter-table::-webkit-scrollbar-thumb {
  background-color: #4f4f4f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.MuiMenu-list > .MuiMenuItem-gutters.Mui-selected {
  color: #64C3FF !important;
}

.errorColor {
  color: #EE3426;
}

/* 30 days trend */
.daystrend401 > .errorComp {
  width: 250px;
}

/* Kpi type */

.kpi-card:hover {
  background-color: #363636;
}

/* Assign asset to Group */
.assign-asset-group-popup{
  width: 99%;
  color: #F0F0F0;
  padding: 1em;
  background-color: #303030;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid black;
  font-weight: 500;
}
/*** Device Cycle **/

.photo-label-upload {
  border: 1.5px solid #64C3FF;
  border-radius: 4px;
  padding: 3px 19px;
  color: #64C3FF;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
  margin: 15px 18px;
  display: block;
}

.state-div {
  width: 100%;
  padding: 20px 0px 20px 0px;
  line-height: 25px;
}

.state-button-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #202020;
}

.btn-asset {
  color: #000000c2 !important;
}

.errorColor {
  color: rgb(241, 23, 23) !important;
}

.errorCompSearch{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 1em;
}

.errorComp1Search{
  width: 35%;
  height: 50%;
  margin-bottom: 2em
}

.errorCompContentSearch{
  letter-spacing: 1.5px;
  color: white;
  margin-bottom: 1.2em;
  text-align: center;
}

/* Generic Css */

.cross-btn::before {
  display: inline-block;
  content: "\00d7";
  color: #606060;
}

.changeColorOnHover {
  color: #F0F0F0;
  cursor: pointer;
}

.changeColorOnHover:hover {
  color: #64C3FF;
}

.elementHoverBlue {
  cursor: pointer;
}

.responsive-legends-LD {
  font-size: 15px;
}

@media only screen and (max-width: 1500px) {
  .responsive-legends-LD {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1400px) {
  .responsive-legends-LD {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1300px) {
  .responsive-legends-LD {
    font-size: 11px;
  }
}

/* highchart label */
g.highcharts-areaspline-series.highcharts-legend-item {
  pointer-events: none;
}

.highcharts-tooltip .highcharts-label > span {
  background-color: red;
}

/* map */
.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after { 
  background: #f0f0f0;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  color: #272727;
}

::-webkit-scrollbar-track-piece {
  color: #272727;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #272727;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #4f4f4f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: #272727;
}

.custom-tablesort-label:hover {
  color: #F0F0F0 !important;
}

.custom-tablesort-label {
  color: #F0F0F0 !important;
}