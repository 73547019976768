.kpiHistoryContainer {
  background-color: #313030;
  border: 1px solid #101010;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.kpiHistoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #222222;
}

.kpi-details-legends {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.kpi-dashboard-legends {
  display: block;
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.kpi-dashboard-legends::-webkit-scrollbar {
  display: none;
}

.kpi-legends-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  overflow-y: auto;
  overflow-x: visible;
  padding: 5px;
  padding-right: 10px;
}

@media only screen and (min-width: 1896px) {
  .kpi-legends-responsive {
    max-height: 350px;
  }
}
